/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --cart-item-grid-template: 1fr 1fr 1fr 1fr;
    --cart-item-grid-gap: 12px;
    --cart-item-product-visual-width: 106px;

    @media (min-width: 1024px) {
        --cart-item-grid-template: minmax(100px, 3fr) 1fr 1fr 1fr 80px;
        --cart-item-grid-gap: 15px;
    }

    @include very-wide-desktop {
        --cart-item-grid-template: minmax(100px, 4fr) 1fr 1fr 1fr 80px;
    }
}

.CartItem {
    --variation-item-width: 22px;

    border-bottom: none;

    &_isCartOverlay {
        --variation-item-width: 16px;
    }

    .CartPage & {
        &_isHidden {
            display: none;
        }
    }

    .ProductVariation {
        &_isActive {
            .ProductVariation-Picture {
                &::after {
                    display: none;
                }
            }
        }
    }

    &-Title {
        container: cartTitle / inline-size;
    }

    &-Heading {
        font-size: 13px;
        font-weight: 700;
        line-height: 1.2;
        white-space: normal;

        &Link {
            font-weight: inherit;
            font-size: inherit;
            color: inherit;

            &:hover {
                color: var(--link-color);
            }
        }
    }

    &-ProductSku,
    &-ProductContenance {
        font-weight: 400;
        font-size: 11px;
        color: #676767;
    }

    &-Wrapper {
        grid-gap: 0;
        padding: 5px 0;

        &_isCart,
        &_isMobileLayout {
            .CartItem-ProductVariation {
                display: flex;
                column-gap: 15px;
                row-gap: 7px;

                button {
                    color: var(--primary-dark-color);
                    font-size: 12px;
                    font-weight: 500;
                    text-decoration: underline;
                    cursor: pointer;
                }

                @container cartTitle (max-width: 220px) {
                    flex-wrap: wrap;
                }
            }
        }

        &_isCart {
            grid-template-columns: var(--cart-item-grid-template);
            grid-template-areas: 'info info info info' 'unitprice qty price actions' 'pickup pickup pickup pickup';
            grid-gap: var(--cart-item-grid-gap);
            padding: 10px 0;

            @media (min-width: 1024px) {
                grid-template-areas: 'info unitprice qty price actions' 'pickup pickup pickup pickup pickup';
            }

            .CartItem-Heading {
                font-size: #{fluid(13px, 16px)};
            }

            .CartItem-ProductSku,
            .CartItem-ProductContenance {
                font-size: 13px;
            }

            .CartItem-ProductActions {
                margin-top: 0;
            }
        }

        &_isMobileLayout {
            grid-template-areas: 'picture rows' 'pickup pickup';
            padding: 10px 0;
            grid-gap: 0;
            column-gap: 14px;
            grid-template-columns: var(--cart-item-product-visual-width) auto;

            @include desktop {
                .CartItem-Heading {
                    font-size: 14px;
                }
            }
        }

        &_isSummary {
            grid-template-columns: var(--cart-item-product-visual-width) auto;
            column-gap: 15px;

            .CartItem {
                &-Picture {
                    width: 100%;
                    height: 0;
                    padding-block-start: var(--ratio-product-image);
                }

                &-Title {
                    width: 100%;
                }

                &-ProductActions {
                    align-items: baseline;
                    justify-content: space-between;
                    padding-block-end: 5px;
                }

                &-Quantity {
                    margin-block-end: 0;
                    font-size: 10px;
                    font-weight: 700;
                    letter-spacing: .1em;
                    text-transform: uppercase;
                }

                &-Price {
                    &.ProductPrice {
                        font-size: 14px;
                    }
                }
            }
        }

        &_isFreeproduct {
            .CartItem {
                &-QuantityWrapper {
                    width: 100px;
                    font-weight: 700;
                    font-size: 13px;
                    text-align: center;

                    @include desktop {
                        font-size: 14px;
                    }

                    .CartItem_isCartOverlay & {
                        width: 92px;
                    }
                }

                &-Price {
                    margin-block-end: 0;
                }
            }
        }
    }

    &-ProductInfo {
        grid-area: info;
    }

    &-Picture {
        &,
        &_isMobileLayout {
            width: var(--cart-item-product-visual-width);
            height: auto;
            aspect-ratio: var(--aspect-ratio-product-image);
        }

        .Image-Image {
            width: calc(100% - var(--product-card-image-padding) * 2);
            height: calc(100% - var(--product-card-image-padding) * 2);
            inset-block-start: var(--product-card-image-padding);
            inset-inline-start: var(--product-card-image-padding);
        }
    }

    &-ProductQty {
        grid-area: qty;
    }

    &-QuantityWrapper {
        > .Field-Wrapper {
            display: flex;
            justify-content: center;
        }
    }

    &-Price {
        display: flex;
        flex-direction: column;
        grid-area: price;
        font-size: 16px;

        @include desktop {
            align-items: center;
            text-align: center;
        }

        /*@media (min-width: 1024px) {
            align-items: flex-start;
            text-align: start;
        }*/

        &_Unit {
            grid-area: unitprice;
        }
    }

    &-ProductActions {
        grid-area: actions;
        justify-content: flex-end;
        margin-top: 12px;

        &_isMobileLayout {
            justify-content: space-between;
            align-items: center;

            @include mobile {
                flex-direction: row-reverse;
            }

            .CartItem-Price {
                @include mobile {
                    font-size: 14px;
                }
            }
        }
    }

    .ProductWishlistButton {
        --wishlist-heart-size: 16px;

        &-Button {
            margin-block-start: 4px;

            &:not(:hover) {
                opacity: 0.4;
            }

            @include desktop {
                margin-block-start: 0;
            }
        }
    }

    &-DeleteButtonText {
        display: none;
    }

    &-Delete {
        margin-block-start: 4px;

        &:not(:hover) {
            opacity: 0.4;
        }

        &_isMobileLayout {
            height: 15px;
        }

        @include desktop {
            margin-block-start: 0;
            margin-inline-start: 20px;

            svg {
                width: 30px;
                height: 30px;
            }
        }
    }

    &-ProductPickUpInfo {
        grid-area: pickup;
        margin-block-start: 10px;
        padding: 0.3em 1em;
        background-color: #F5F5F5;
        font-weight: 500;
        font-size: 12px;
        color: #888;
        text-align: center;

        @include desktop {
            .CartItem-Wrapper_isCart & {
                margin-block-start: 0;
            }
        }
    }

    &-ProductVariation {
        margin-block-start: 10px;
        font-size: 12px;
    }

    .CartItem-QuantityWrapper {
        &_isCartOverlay {
            & button {
                width: 30px;
                height: 30px;
            }

            .CartItem-Qty {
                font-size: 14px;
            }
        }
    }

    &-OutOfStock {
        font-size: 12px;
        margin-top: 5px;

        &::before {
            flex: 0 0 auto;
            content: '';
            display: inline-block;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background-color: #FF0000;
            margin-right: 0.75em;
        }
    }
}
