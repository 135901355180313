/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartGiftWrap {
    background-color: #f8ebea;
    margin-bottom: 30px;
    margin-top: 20px;
    padding: 15px;
    display: flex;
    gap: 25px;
    align-items: center;

    &-Visual {
        max-width: 100px;

        .Image {
            width: 84px;
            height: 84px;
            border-radius: 50%;
            background-color: $white;
        }
    }

    h3 {
        margin-top: 0;
        text-transform: inherit;
        font-size: 14px;
        margin-bottom: 0;
        font-weight: 700;
    }

    &-VisualThumbnail {
        aspect-ratio: 1;
    }

    &-ProductList {
        .ProductListPage {
            grid-template-columns: 1fr;
            grid-row-gap: 15px;
        }

        @include product-card-horizontal();
    }

    .Button_likeLink {
        text-decoration: underline;
        font-weight: 500;
        margin-top: 10px;
        justify-content: flex-start;
    }

    &-Price {
        @include mobile {
            font-size: 12px;
        }
    }
}
