/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@include desktop {
    .CartOverlayPopup {
        .Popup-Heading {
            position: absolute;
            top: 20px;
            height: 50px;
        }

        .Popup-Main {
            overflow-y: hidden;
            padding-bottom: 20px;
            padding-right: 0px;
        }
    }
}

.CartOverlay {
    @include desktop {
        .CartOverlayPopup & {
            position: relative;
            inset: auto;
            width: auto;
            right: 0 !important;

            &::before {
                display: none;
            }
        }
    }

    &-ContentWrapper {
        border: none;

        @include desktop {
            height: calc( 100vh - 70px);
            display: flex;
            flex-direction: column;
        }
    }

    &-ItemsCount {
        font-weight: 300;
        font-size: 16px;
    }

    &-Items {
        padding: 0;
        margin-top: 25px;
        max-height: none;

        @include desktop {
            padding: 20px 20px 20px 0;
            flex: 1;
            min-height: 0;
            overflow: auto;
            margin-top: 15px;
            scrollbar-width: thin;
        }
    }

    &-Additional {
        border-top: solid 1px #c3c3c3;
        padding-top: 15px;
        margin-top: 35px;

        @include desktop {
            flex: 0 0 auto;
            margin-top: 15px;
            padding-right: var(--popup-content-padding);
        }
    }

    &-Subtotal, &-Discount {
        display: flex;
        justify-content: space-between;
        color: #797979;
        font-weight: bold;
        font-size: 12px;
        padding-bottom: 15px;

        dd {
            font-size: 13px;
            font-weight: 600;
        }
    }

    &-Discount {
        background: transparent;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }

    &-Tax {
        background: none;
        padding: 0;
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
        color: #797979;
        font-weight: bold;
        font-size: 12px;

        dd {
            font-size: 13px;
            font-weight: 600;
        }
    }

    &-Shipping {
        display: flex;
        justify-content: space-between;
        color: #797979;
        font-weight: bold;
        font-size: 12px;
        padding-bottom: 15px;

        dd {
            font-size: 13px;
            font-weight: 600;
        }
    }

    &-Total {
        border-top: solid 1px #c3c3c3;
        background: none;
        padding: 0;
        padding-top: 15px;

        @include desktop {
            padding-top: 10px;
        }

        dt {
            letter-spacing: 1.2px;
            text-transform: uppercase;
            font-size: 12px;
        }

        dd {
            font-size: 16px;
            font-weight: bold;
        }
    }

    &-FreeShipping {
        .CartFreeShipping {
            margin-block: 20px 0;
        }
    }

    &-Actions {
        text-align: center;
        display: block;
        margin-top: 20px;
        padding: 16px 0;

        .Button {
            display: flex;
            width: 100%;
        }
    }
}
