/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartCoupon {
    .Form {
        display: block;
        margin-block-start: 0;
    }

    &-Item {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        gap: 10px;
        margin-block-end: 20px;

        &Code {
            font-weight: 700;
            font-size: 11px;
        }

        &Description {
            color: #6F6F6F;
            font-weight: 300;
            font-size: 11px;
        }

        .CartCoupon-Button {
            gap: .3em;
            margin-block: 0;
            color: var(--primary-dark-color);
            font-size: 12px;
            font-weight: 500;
            text-transform: none;
            letter-spacing: 0;

            &::before {
                content: "+";
            }

            &::after {
                content: "";
                display: block;
                width: 100%;
                border-block-end: 1px solid var(--primary-dark-color);
                position: absolute;
                inset-inline: 0;
                inset-block-end: 0;
            }
        }
    }

    .CartCoupon-Input {
        width: 100%;
        margin-inline-end: 0;

        input {
            width: 100%;
        }
    }

    .CartCoupon-Form {
        button {
            position: absolute;
            top: 0;
            right: 0;
            border: none;
            text-decoration: underline;
            color: var(--primary-dark-color);
            font-size: 14px;
            font-weight: 500;
            text-transform: none;
            letter-spacing: 0;

            @include mobile {
                width: auto;
                margin-block: 0;
            }
        }
    }

    &-CurrentTitle,
    &-AppliedTitle {
        margin-block: 20px 10px;
        font-size: 12px;
        font-weight: 500;
    }

    &-CurrentItems {
        border-block-end: 1px solid var(--primary-divider-color);
        margin-block-end: 10px;
    }

    &-Applied {
        .CartCoupon-Button {
            &::before {
                content: "\002212";
            }
        }
    }
}
