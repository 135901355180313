/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.AddToCart {
    flex-direction: column;

    span {
        line-height: 1.3;

        &.AddToCart-SubLabel {
            font-size: 10px;
            font-weight: 500;
            color: var(--color-black);
            text-transform: none;
            letter-spacing: 0;
            opacity: .5;
        }
    }

    &[disabled] {
        --button-background: #E0E0E0;
        --button-border: var(--button-background);

        opacity: 1;
    }
}
