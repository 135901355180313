/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartPage {
    @include mobile {
        padding-block-start: 10px;
        padding-block-end: 0;
    }

    &-Wrapper {
        > * {
            overflow: hidden;  /* NEW */
            min-width: 0;      /* NEW; needed for Firefox */
        }

        @include desktop {
            grid-template-columns: 1fr 420px;
            grid-column-gap: 78px;
        }

        @include narrow-desktop {
            grid-template-columns: 1fr 320px;
            grid-column-gap: 30px;
        }

        @include ultra-narrow-desktop {
            grid-template-columns: 1fr 320px;
            grid-column-gap: 30px;
        }
    }

    &-Heading {
        @include mobile {
            display: block;
            text-align: center;
        }
    }

    &-Title {
        display: inline-block;
    }

    &-ItemsCount {
        background: var(--primary-base-color);
        border-radius: 50%;
        color: $white;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
        vertical-align: top;
        margin-left: 15px;
    }

    &-TableHead {
        display: none;
        grid-template-columns: var(--cart-item-grid-template);
        grid-gap: var(--cart-item-grid-gap);
        background: none;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 1.1px;
        padding: 0;
        padding-bottom: 15px;
        border-bottom: solid 1px #c3c3c3;
        border-radius: 0;

        @media (min-width: 1024px) {
            display: grid;
        }

        span {
            &,
            &:last-of-type {
                [dir="ltr"] & {
                    text-align: center;
                }
            }

            &:first-child {
                text-align: start;
            }
        }
    }

    &-Floating {
        @include desktop {
            background-color: var(--secondary-light-color);
            padding: 30px 40px;
            margin-top: 100px;
        }
    }

    &-SummaryTitle {
        h2 {
            margin-top: 0;
            text-transform: uppercase;
            font-size: 16px;
            text-align: center;
        }
    }

    .CustomerService {
        flex-direction: column;
        margin-top: 10px;

        &::before {
            display: block;
            content: "";
            width: 50px;
            height: 1px;
            background: var(--primary-divider-color);
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
        }

        &-Title {
            font-size: 13px;
            margin-right: 0;
        }
    }

    &-RemoveAll {
        text-align: right;
        font-size: 12px;

        button {
            text-decoration: underline;
            color: $black;
            font-size: 12px;
            font-weight: 500;
            width: auto;
        }
    }

    &-Summary {
        background: none;
    }

    .CheckoutOrderSummary {
        border: none;
        padding: 0;
    }

    &-ShippingInfo {
        border-top: 1px solid var(--primary-divider-color);
        border-bottom: 1px solid var(--primary-divider-color);
        padding: 15px 0;
        padding-right: 10px;
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 40px;

        button {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }

    &-Items {
        margin-bottom: 20px;
    }
}
