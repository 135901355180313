/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartPopup {
    .Popup-Content {
        padding: 0;
    }

    .Popup-Header {
        min-height: 45px;

        @include desktop {
            min-height: 80px;
        }
    }

    .Popup-Main {
        display: flex;
        flex-direction: column;
        padding-block-end: 0;
    }

    &.Popup_isVisible {
        @include mobile {
            z-index: 1000;
            align-items: end;
            padding-top: 24px;
        }
    }

    &-Product {
        display: grid;
        padding-bottom: 15px;
        border-bottom: solid 1px #ededed;

        @include mobile {
            margin-bottom: 20px;
            grid-column-gap: 20px;
            grid-template-columns: 70px auto;
        }

        @include desktop {
            margin-bottom: 30px;
            grid-column-gap: 25px;
            grid-template-columns: 110px auto;
        }
    }

    &-ProductTitle {
        @include mobile {
            font-size: 15px;
        }

        @include desktop {
            font-size: 16px;
        }

        span {
            display: block;
            font-weight: 300;

            @include mobile {
                font-size: 20px;
                margin-bottom: 6px;
            }

            @include desktop {
                font-size: 22px;
                margin-bottom: 10px;
            }
        }
    }

    &-ProductQuantity {
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1.2px;

        @include mobile {
            font-size: 10px;
            margin-top: 10px;
        }

        @include desktop {
            font-size: 12px;
            margin-top: 15px;
        }
    }

    &-ProductThumbnail {
        height: 0;
        padding-top: var(--ratio-product-image);
    }

    &-CrossSell {
        margin-block-start: auto;
        background: var(--secondary-light-color);

        @include desktop {
            padding-top: 40px;
            margin-left: -45px;
            margin-right: -45px;
            padding-left: 70px;
            padding-right: 45px;
        }

        @include mobile {
            margin-left: -20px;
            margin-right: -20px;
            padding: 20px;
            padding-top: 30px;
        }

        &Title {
            font-size: 22px;
            margin-bottom: 35px;
            font-weight: 300;

            @include mobile {
                text-align: center;
            }
        }

        .ProductListWidget-Page,
        .ProductListPage {
            grid-template-columns: 1fr;
            grid-row-gap: 15px;
        }

        @include product-card-horizontal();

        .ProductCard .AddToCart {
            --product-card-btn-addtocart-width: 46px;

            margin-inline-end: 0;
        }

        .ProductPrice-PriceValue {
            font-size: 14px;
        }

        .ProductCard {
            &-FigureReview {
                @include desktop {
                    flex: 0 0 85px;
                }

                @include mobile {
                    flex: 0 0 29%;
                    max-width: 29%; // fix swiper
                }
            }

            &-Content {
                padding-top: 0;
                justify-content: center;
            }

            &-Name {
                margin-bottom: 0;
                padding-bottom: 0;

                @include desktop {
                    max-width: 225px;
                }
            }
        }
    }

    &-TotalQuantity {
        @include mobile {
            font-size: 14px;
            margin-bottom: 20px;
        }

        @include desktop {
            font-size: 16px;
            margin-bottom: 25px;
        }
    }

    &-Total {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        margin-bottom: 10px;

        dt {
            text-transform: uppercase;

            @include mobile {
                font-size: 12px;
                letter-spacing: 1.2px;
            }

            @include desktop {
                font-size: 14px;
                letter-spacing: 1.4px;
            }
        }

        dd {
            @include mobile {
                font-size: 16px;
            }

            @include desktop {
                font-size: 18px;
            }
        }
    }

    &-Actions {
        --button-padding: 90px;
        --button-hover-padding: 90px;

        text-align: center;

        @include mobile {
            margin-top: 20px;
            padding-bottom: 20px;
        }

        @include desktop {
            margin-top: 40px;
            padding-bottom: 50px;
        }
    }

    &-CartButton {
        @include mobile {
            --button-padding: 40px;
        }
    }
}
